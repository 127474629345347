// mobile menu toggle
$(document).ready(function(){
  let burger = $(".hamburger"),
      mobileMenu = $(".main-navigation-mobile-wrapper"),
      mobileSolutionsLink = $(".js-mobile-solutions-link"),
      mobileSolutionsMenu = $(".js-mobile-solutions");

  burger.on("click", function(){
      mobileMenu.slideToggle();
      burger.toggleClass("is-active");
      $("body").toggleClass("iphone-fix");
  });

  mobileSolutionsLink.on("click", function(e){
    e.preventDefault();
    mobileSolutionsMenu.slideToggle();
  });
})
